import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';

import 'swiper/css';

export default class FeaturedWorkSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.work-list-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const FeaturedWorkSwiperSwiper = new Swiper('.work-list', {

                pagination: {
                    el: ".swiper-pagination",
                    dynamicBullets: true,
                  },

                autoplay: {
                    delay: 4000,
                },

                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,

                breakpoints: {
                   1025: {
                    slidesPerView: 3
                   } 
                },

                modules: [Navigation, Pagination, Autoplay]
            })
        })
    }
}