export default class WhatWeDo {

    constructor() {
        const widgets = document.querySelectorAll('.elementor-widget-net_what_we_do');

        if (!widgets.length) {
            return;
        }

        widgets.forEach(widget => {
            this.initWidget(widget as HTMLElement);
        });
    }

    initWidget(widget: HTMLElement) {
        const tabs = widget.querySelectorAll('.what-we-do-tab');
        const nav = widget.querySelectorAll('.what-we-do-nav-item');

        if (!tabs.length && !nav.length) {
            console.warn('Invalid markup, missing tab or nav.');
            return;
        }

        const closeAll = () => {
            nav.forEach(item => {
                item.classList.remove('is-active');
                item.setAttribute('tabindex', '-2');
                item.setAttribute('aria-selected', 'false');
            });

            tabs.forEach(tab => tab.classList.remove('is-active'));
        };

        nav.forEach(item => {
            item.addEventListener('click', () => {
                closeAll();

                console.log('i clicked')

                item.classList.add('is-active');
                item.setAttribute('aria-selected', 'true');
                item.removeAttribute('tabindex');

                const panel = document.getElementById(item.getAttribute('aria-control') || '');

                if (!panel) {
                    console.warn('Invalid panel id', item.getAttribute('aria-control'));
                    return;
                }

                panel.classList.add('is-active');
            });
        });
    }

}
