import '@styles/common/tailwind.css';
import '@styles/main.scss';

import { isFrontPage } from './util/route-utils';
import homepageIntro from './animations/home-header-into';
import menu from './components/menu';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import lottie from 'lottie-web';
import logoAnimation from './animations/net-logo.json';
import Work from '@scripts/widgets/work';
import WhatWeDo from '@scripts/widgets/what-we-do';
import FeaturedWorkSwiper from './widgets/featured-work-swiper';

gsap.registerPlugin(ScrollTrigger);

class App {
    constructor() {

        this.animations();
        menu();
        this.headerAnimation();
        this.pinSections();
        new Work();
        new WhatWeDo();
        new FeaturedWorkSwiper();
    }

    animations() {
        if (isFrontPage()) {
            homepageIntro();
        }
    }

    headerAnimation() {
        const header = document.getElementById('site-header'),
            logoAnim = document.getElementById('bm');

        if (!header) {
            return;
        }

        const animation = lottie.loadAnimation({
            container: logoAnim,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: logoAnimation,
        });

        window.addEventListener('scroll', () => {
            const y = window.scrollY;
            if (y > 500 && !header.classList.contains('is-fixed')) {
                header.classList.add('is-fixed');
                animation.stop();
                animation.play();
            } else if (y < 500) {
                header.classList.remove('is-fixed');
            }
        });
    }

    pinSections() {
        const pins = document.querySelectorAll('.fx--pin') as NodeListOf<HTMLElement>;

        for (let i = 0; i < pins.length; i++) {
            const pin = pins[i];
            const options: ScrollTrigger.StaticVars = {
                trigger: pin,
                start: 'top top',
                pinSpacing: false,
                pin: i === pins.length - 1 ? false : true,
                end: 'bottom 100',
                // markers: true,
            };

            ScrollTrigger.create(options);
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
