import { gsap } from 'gsap';

export default () => {
    const textLetters = document.querySelectorAll('.word-rotation .word');

    for (let i = 0; i < textLetters.length; i++) {
        const word = textLetters[i];

        if (!word.textContent) {
            continue;
        }

        word.innerHTML = word.textContent.replace(
            /\S/g,
            "<span class='letter'>$&</span>"
        );
    }

    const wordTimeline = gsap.timeline({
        delay: 3,
        repeat: -1,
    });

    for (let i = 0; i < textLetters.length; i++) {
        const word = textLetters[i],
            letters = word.querySelectorAll('.letter');

        wordTimeline.fromTo(
            letters,
            {
                opacity: 0,
                y: 15,
            },
            {
                opacity: 1,
                y: 0,
                stagger: 0.1,
                ease: 'power1.inOut',
            },
            '-=0.5'
        );

        wordTimeline.to(
            letters,
            {
                opacity: 0,
                y: -15,
                stagger: 0.1,
                ease: 'power1.inOut',
            },
            '+=2.2'
        );
    }

    const timeline = gsap.timeline({
        delay: 1,
    });

    timeline.fromTo(
        '.home-header .large-heading',
        { opacity: 0, y: 10 },
        { opacity: 1, y: 0, ease: 'power3.out', duration: 1.4 }
    );
    timeline.fromTo(
        '.home-header .scroll-text',
        { opacity: 0 },
        { opacity: 1 },
        '-=0.2'
    );
    timeline.fromTo(
        '.home-header .button-group',
        { opacity: 0 },
        { opacity: 1 },
        '+=1.0'
    );

};
