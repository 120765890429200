import Shuffle from 'shufflejs';
import Muuri from 'muuri';
import type Grid from "muuri";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class Work {
    private muuri: Grid | null = null;
    private filter = '';
    constructor() {
        const grid = document.querySelector('.work-list-filtered');

        if (!grid) {
            return;
        }

        this.muuri = new Muuri('.work-list-filtered');

        const selector = document.querySelector('.work-category-selector');
        const filters = document.querySelectorAll('.work-category__item') as NodeListOf<HTMLElement>;

        if (!selector) {
            console.warn('Missing Filter Selector');
            return;
        }

        const dropdown = tippy(selector, {
            content: document.querySelector('.work-categories-dropdown') as any,
            allowHTML: true,
            interactive: true,
            trigger: 'click',
            placement: 'bottom',
            arrow: false,
            theme: 'net-dropdown',
            onShow() {
                selector.classList.add('is-active');
            },
            onHide() {
                selector.classList.remove('is-active');
            }
        });

        filters.forEach((el) => {
            el.addEventListener('click', () => {
                const value = el.dataset.value;
                if (!value) {
                    return;
                }

                if (this.filter === value) {
                    selector.querySelector('span')!.textContent = 'Please Select';
                    this.filter = '';
                } else {
                    selector.querySelector('span')!.textContent = el.textContent;
                    this.filter = value;
                }

                dropdown.hide();

                this.updateFilter();
            })
        });
    }

    updateFilter() {
        this.muuri?.filter((item: any) => {
            if (!this.filter.length) {
                return true;
            }

            const values = JSON.parse(item.getElement().dataset.categories);

            return values.includes(this.filter);
            // return this.filters.some(filter => values.includes(filter));
        });
    }
}
